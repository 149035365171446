import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, QueryResult } from '@apollo/client';

import { PageHeader, Loader } from 'Components';
import { showToastError, showToastSuccess } from 'Utils';
import {
    GET_PLATFORM_NETWORKS_QUERY,
    CREATE_SHIELD_TEMPLATE_MUTATION,
    GET_SHIELD_TEMPLATES_QUERY,
} from 'Queries';
import { CreateShieldTemplateMutation, CreateShieldTemplateVariables, GetPlatformNetworksQueryType } from 'Types';

import { FormContent } from './formContent';
import { getInitialValues, validationSchema } from './config';
import { IValues } from './interfaces';
import * as Styled from './styled';

export const AddShieldTemplate: React.FC = () => {
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const { data: networksData, loading }: QueryResult<GetPlatformNetworksQueryType> = useQuery(GET_PLATFORM_NETWORKS_QUERY);
    const networks = networksData?.getPlatformNetworks || [];

    const [createShieldTemplateMutation] = useMutation<
        CreateShieldTemplateMutation,
        CreateShieldTemplateVariables
    >(CREATE_SHIELD_TEMPLATE_MUTATION, {
        refetchQueries: [GET_SHIELD_TEMPLATES_QUERY],
        onCompleted: (data) => {
            if (data?.createShieldTemplate) {
                showToastSuccess('Successfully created');
                navigate(-1);
            } else {
                showToastError('Could not create a new template');
            }
        },
    });

    useEffect(
        () => {
            if (!loading) {
                setInitialLoading(false);
            }
        },
        [loading]
    );

    const navigateBack = () => navigate(-1);

    const onSubmit = async (values: IValues): Promise<void> => {
        const { networks, ...rest } = values;
        const supportedNetworks: string[] = [];
        const unsupportedNetworks: string[] = [];

        networks.forEach((network) => {
            if(network.check) {
                supportedNetworks.push(network.key);
            } else {
                unsupportedNetworks.push(network.key);
            }
        });

        const sensors = values.sensors.filter((sensor) => !!sensor.trim());
        const supportedDevices = values.supportedDevices.filter((device) => !!device.trim());

        const input = { ...rest, supportedNetworks, unsupportedNetworks, sensors, supportedDevices };
        createShieldTemplateMutation({ variables: { input } });
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                title="Add shield template"
                description={[
                    'The changes you make, will be applied to main service to be displayed in section of adding a new device',
                ]}
            />
            <Styled.Content>
                <Formik
                    initialValues={getInitialValues(networks)}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => (
                        <FormContent
                            {...props}
                            onClickBack={navigateBack}
                            formMode="New"
                        />
                    )}
                </Formik>
            </Styled.Content>
        </>
    );
};
